import { useQueries } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { unitMethods } from "../../api/apiMethods";
import { ReplacementWindowGroup, TimeRestriction } from "../../common/types";
import { UserContext } from "../../contexts/UserContext";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { UnitListingItem } from "../../views/UnitView/unitTypes";
import { queryKeys } from "../queryKeys";
import { useAllUnitsQuery } from "./useAllUnitsQuery";

// https://github.com/TanStack/query/issues/6369#issuecomment-1888801451
const emptyRecord = {};

export const useUnitListingDataQuery = () => {
    const query = useAllUnitsQuery();
    useQueryErrorHandling(query, "Kunde inte hämta enheter.");

    const { authUserData } = useContext(UserContext);
    const municipalityId = useSelectedMunicipalityId();

    const fetchAuthorized = authUserData?.municipalityRole === "ADMIN";

    const restrictionQueries = useQueries({
        queries: (query.data || []).map((unit) => ({
            queryKey: queryKeys.unit.restrictions(municipalityId, unit.code),
            queryFn: async () => {
                if (!municipalityId) {
                    throw new Error("No municipality Id");
                }

                try {
                    return await unitMethods(
                        municipalityId
                    ).getUnitRestrictions(unit.code);
                } catch (error) {
                    throw new Error("Could not get unit restrictions data");
                }
            },
            // @ts-ignore https://github.com/TanStack/query/issues/3994
            select: (response) => ({
                unitId: unit.code,
                homeTimeWindowRestrictions: response.homeTimeWindowRestrictions,
            }),
            enabled: fetchAuthorized,
            staleTime: 1000 * 60 * 5,
        })),
        combine: (data) => {
            return data.reduce<
                Record<
                    string,
                    {
                        isPending: boolean;
                        data: TimeRestriction[];
                    }
                >
            >((acc, result) => {
                const { data, isPending } = result;
                if (!data) return acc;
                return {
                    ...acc,
                    [data.unitId]: {
                        isPending: isPending,
                        data: data.homeTimeWindowRestrictions,
                    },
                };
            }, emptyRecord);
        },
    });

    const replacementsQueries = useQueries({
        queries: (query.data || []).map((unit) => ({
            queryKey: queryKeys.unit.replacements(municipalityId, unit.code),
            queryFn: async () => {
                if (!municipalityId) {
                    throw new Error("No municipality Id");
                }

                try {
                    return await unitMethods(
                        municipalityId
                    ).getUnitReplacementWindows(unit.code);
                } catch (error) {
                    throw new Error("Could not get unit replacements data");
                }
            },
            select: (
                // @ts-ignore https://github.com/TanStack/query/issues/3994
                response
            ): {
                unitId: string;
                windowReplacementConfigurations: ReplacementWindowGroup[];
            } => ({
                unitId: unit.code,
                windowReplacementConfigurations:
                    response.windowReplacementConfigurations,
            }),
            enabled: fetchAuthorized,
            staleTime: 1000 * 60 * 5,
        })),
        combine: (data) => {
            return data.reduce<
                Record<
                    string,
                    {
                        isPending: boolean;
                        data: any;
                    }
                >
            >((acc, result) => {
                const { data, isPending } = result;
                if (!data) return acc;
                return {
                    ...acc,
                    [data.unitId]: {
                        isPending: isPending,
                        data: data.windowReplacementConfigurations,
                    },
                };
            }, emptyRecord);
        },
    });

    const data = useMemo(() => {
        if (!query.data || !restrictionQueries || !replacementsQueries) {
            return [];
        }

        return query.data.map(
            (unit) =>
                ({
                    data: unit,
                    restrictions: restrictionQueries[unit.code],
                    replacements: replacementsQueries[unit.code],
                } as UnitListingItem)
        );
    }, [query.data, replacementsQueries, restrictionQueries]);

    return { data: data, isLoading: query.isLoading };
};
