import React, { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { routes } from "../routes";
import Loader from "./Loader";

const CustomerCreationAuthorized = (props: PropsWithChildren<{}>) => {
    const { authUserData } = useContext(UserContext);
    const municipalityId = useSelectedMunicipalityId();

    const isAuthorized =
        authUserData &&
        (authUserData.customerCreationAllowed === true ||
            authUserData.municipalityRole === "ADMIN");

    if (isAuthorized === true) {
        return <>{props.children}</>;
    }

    if (isAuthorized === false) {
        return <Navigate to={routes.customers(municipalityId)} replace />;
    }

    return <Loader fullHeight />;
};

export default CustomerCreationAuthorized;
