import React, { useContext, useEffect, useState } from "react";
import { userMethods } from "../api/apiMethods";
import { identityConfig } from "../auth/authConst";
import { AuthContext } from "../auth/authProvider";
import { genericErrorConfig } from "../common/exceptions";
import { AuthContextObject, UserContext } from "../contexts/UserContext";
import { Logger } from "../utils/logger/Logger";
import { ModalContext } from "./Modal";

const UserContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { signout } = useContext(AuthContext);
    const { setModalOptions } = useContext(ModalContext);
    const [authUserData, setAuthUserData] = useState<AuthContextObject>();

    useEffect(() => {
        const session = `oidc.user:${identityConfig.authority}:${identityConfig.client_id}`;
        const storageObj = sessionStorage.getItem(session);

        if (!storageObj) {
            return;
        }

        const userId = JSON.parse(storageObj)["profile"]["sub"] as string;
        userMethods()
            .getUser(userId)
            .then((res) => {
                setAuthUserData({
                    ...res,
                    identityId: userId,
                });
            })
            .catch((error) => {
                Logger.error({ userId, error });
                setModalOptions(
                    genericErrorConfig(
                        () => {
                            setModalOptions({ visible: false });
                            signout();
                        },
                        "Din inloggningssession har löpt ut.",
                        "Vi skickar dig till inloggningssidan."
                    )
                );
            });
    }, [setModalOptions, signout]);

    useEffect(() => {}, []);

    return (
        <UserContext.Provider
            value={{
                authUserData,
                setAuthUserData,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
