import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { customerMethods } from "../api/apiMethods";
import { CustomerDTO, PaginationDTO } from "../api/apiTypes";
import { AuthContextObject, UserContext } from "../contexts/UserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

const fetchCustomersForCurrentAuthUser = async (
    authUserData: AuthContextObject,
    municipalityId: string,
    page: number,
    pageSize: number,
    selectedUnitIds?: string[],
    searchQuery?: string
) => {
    if (authUserData.municipalityRole === "ADMIN") {
        const response = await customerMethods(municipalityId).getAllCustomers(
            page,
            pageSize,
            selectedUnitIds,
            searchQuery
        );

        return response;
    }

    if (authUserData.municipalityRole === "ORDERER") {
        const customersByUnit = await customerMethods(
            municipalityId
        ).getAllCustomers(
            page,
            pageSize,
            !!selectedUnitIds?.length
                ? authUserData.authorisedUnitCodes?.filter((item) =>
                      selectedUnitIds.includes(item)
                  )
                : authUserData.authorisedUnitCodes,
            searchQuery
        );

        return customersByUnit;
    }

    return Promise.resolve({
        userlist: [] as CustomerDTO[],
        pagination: undefined as PaginationDTO | undefined,
    });
};

export const useAllCustomersQuery = (
    page: number,
    pageSize: number,
    selectedUnitIds?: string[],
    searchQuery?: string
) => {
    const { authUserData } = useContext(UserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.customer.all(
            municipalityId,
            page,
            pageSize,
            selectedUnitIds,
            searchQuery
        ),
        queryFn: () => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return fetchCustomersForCurrentAuthUser(
                    authUserData,
                    municipalityId,
                    page,
                    pageSize,
                    selectedUnitIds,
                    searchQuery
                );
            } catch (error: any) {
                throw new Error(error.message);
            }
        },
        select: (data) => ({
            userList: data?.userlist.filter(
                (item) => !item.deactivationDate?.length
            ),
            pagination: data?.pagination,
        }),
        // staleTime: 1000 * 60 * 5, enable stale time only when we have mutations for Customers and query invalidation on mutation's success
        enabled: !!authUserData,
        placeholderData: keepPreviousData,
    });

    useQueryErrorHandling(query, "Kunde inte hämta slutkunder.");

    return query;
};
