import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { userMethods } from "../api/apiMethods";
import { UserContext } from "../contexts/UserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import { Logger } from "../utils/logger/Logger";
import { queryKeys } from "./queryKeys";

export const useAllowedMunicipalitiesQuery = () => {
    const { authUserData } = useContext(UserContext);

    const query = useQuery({
        queryKey: queryKeys.municipality.allowedMunicipalities(
            authUserData?.identityId
        ),
        queryFn: () => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            try {
                return userMethods().getAllowedMunicipalities();
            } catch (error: any) {
                Logger.errorWithMessage(
                    `Failed to get allowed municipalities for ADMIN`,
                    error
                );
                throw new Error(error.message);
            }
        },
        select: ({ municipalities }) => municipalities,
        enabled:
            !!authUserData &&
            !!authUserData.identityId &&
            authUserData.municipalityRole === "ADMIN",
        staleTime: Infinity,
        retry: 1,
    });

    useQueryErrorHandling(query, "Kunde inte hämta tillåtna kommuner.");

    return query;
};
