import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../auth/authProvider";
import Button from "../../components/Button";
import { UserContext } from "../../contexts/UserContext";
import { telemetry } from "../../utils/telemetry/TelemetryService";
import MunicipalityBaseView from "./MunicipalityBaseView";

const MunicipalityAccessError = () => {
    const { signout } = useContext(AuthContext);
    const { authUserData } = useContext(UserContext);

    useEffect(() => {
        telemetry.trackError(new Error("Municipality Access Error"), {
            customerId: authUserData?.customerId,
            identityId: authUserData?.identityId,
        });
    }, [authUserData]);

    return (
        <MunicipalityBaseView
            title="Någonting gick fel"
            subTitle="Testa att logga ut och sedan in igen">
            <Button theme={"greenDark"} onClick={signout} title="Logga ut" />
        </MunicipalityBaseView>
    );
};

export default MunicipalityAccessError;
