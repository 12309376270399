import React, { useContext, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { genericErrorConfig } from "../../common/exceptions";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Cell, { CellHeading } from "../../components/Grid/Cell";
import CellBlock, { CellBlockFooter } from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import { ModalContext } from "../../components/Modal";
import { UserContext } from "../../contexts/UserContext";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { useCustomerCreateMutation } from "../../mutations/customer/useCustomerCreateMutation";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useUnitRestrictionsQuery } from "../../queries/units/useUnitRestrictionsQuery";
import { useAvailablePaymentMethodsForMunicipalityQuery } from "../../queries/useAvailablePaymentMethodsForMunicipalityQuery";
import { routes } from "../../routes";
import { filterCurrentUserAuthorizedUnits } from "../OrdererView/OrdererView.utils";
import CustomerDeliveryForm from "./CustomerDeliveryForm";
import CustomerInfoForm from "./CustomerInfoForm";
import { CustomerFormData, useCreateCustomerForm } from "./CustomerView.hooks";
import { getModalCustomerErrorText } from "./customerDetailsConfig";

const NewCustomerSettingsView = () => {
    const navigate = useNavigate();
    const { authUserData } = useContext(UserContext);
    const { setModalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();

    const form = useCreateCustomerForm();

    const watchUnit = useWatch({
        control: form.control,
        name: "unit",
    });

    const { data: availablePaymentMethods } =
        useAvailablePaymentMethodsForMunicipalityQuery();

    const { data: availableTimeFrames } = useUnitRestrictionsQuery(
        watchUnit?.id
    );

    const { data: allUnits } = useAllUnitsQuery();
    const availableUnits = useMemo(
        () =>
            allUnits &&
            authUserData &&
            filterCurrentUserAuthorizedUnits(authUserData, allUnits),
        [allUnits, authUserData]
    );

    const createMutation = useCustomerCreateMutation({
        onSuccess: (customerId) => {
            navigate(
                {
                    pathname: routes.customerById(municipalityId, customerId),
                },
                { replace: true }
            );
        },
        onGenericError: ({ message }) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    message
                )
            );
        },
        onFieldValidationError: ({ message, jsonError, fieldErrors }) => {
            if (fieldErrors?.length) {
                fieldErrors.forEach((err) => {
                    form.setError(err.fieldName as any, {
                        message: err.message,
                    });
                });
                return;
            }
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    message,
                    getModalCustomerErrorText(jsonError)
                )
            );
        },
        onBillingCreateError: (customerId) => {
            // route to newly created customer with an invalid billingaddress
            // because we create a customer first before we try to create billingAddress
            const onDismiss = () => {
                setModalOptions({
                    visible: false,
                });

                navigate(routes.customerById(municipalityId, customerId), {
                    replace: true,
                });
            };

            setModalOptions({
                visible: true,
                title: `Slutkunden skapades med ett fel`,
                subtitle: `Misslyckades att spara leveransinformationen, vänligen prova igen.`,
                onDismiss: onDismiss,
                buttons: [
                    <Button
                        size="small"
                        title="Jag förstår"
                        onClick={onDismiss}
                    />,
                ],
            });
        },
    });

    const saveCustomerDetails = (data: CustomerFormData) => {
        createMutation.mutate({
            formData: data,
        });
    };

    const isPending = createMutation.isPending;

    return (
        <>
            <form onSubmit={form.handleSubmit(saveCustomerDetails)}>
                <Grid gutter={space.padding}>
                    <Cell
                        gutter={space.padding}
                        extraPaddingRight={space.xLarge}>
                        <CellHeading>Personuppgifter</CellHeading>
                        <CellBlock
                            active={true}
                            noOutlines={true}
                            style={{ marginBottom: "20px" }}
                            onEdit={undefined}>
                            <CustomerInfoForm form={form} enableFormDevTool />
                            <CellBlockFooter
                                marginTop
                                hideInMobile
                                disableSubmit={isPending}
                                isLoading={isPending}
                                hasChangesText={undefined}
                                onCancel={() => navigate(-1)}
                                submitButtonType="submit"
                            />
                        </CellBlock>
                    </Cell>
                    <Cell
                        gutter={space.padding}
                        extraPaddingLeft={space.xLarge}>
                        <CellHeading>
                            Betalning & Leveransinformation
                        </CellHeading>
                        <CellBlock
                            active={true}
                            noOutlines={true}
                            onEdit={undefined}>
                            <CustomerDeliveryForm
                                availablePaymentMethods={
                                    availablePaymentMethods
                                }
                                availableTimeFrames={availableTimeFrames}
                                availableUnits={availableUnits}
                                isNewForm={true}
                                enableFormDevTool={false}
                                form={form}
                            />
                            <CellBlockFooter
                                hideInDesktop
                                marginTop
                                disableSubmit={isPending}
                                isLoading={isPending}
                                hasChangesText={undefined}
                                onCancel={() => navigate(-1)}
                                submitButtonType="submit"
                            />
                        </CellBlock>
                    </Cell>
                </Grid>
            </form>
        </>
    );
};

export default NewCustomerSettingsView;
