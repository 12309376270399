import React from "react";
import { Navigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useAllowedMunicipalities } from "../../hooks/useAllowedMunicipalities";
import { routes } from "../../routes";

const MunicipalityAuthorization = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const allowedMunicipalities = useAllowedMunicipalities();

    const params = useParams<{ municipalityId: string }>();

    if (!allowedMunicipalities) {
        return <Loader fullHeight />;
    }

    // no allowed municipialities, this is an error, redirect to access denied page
    if (allowedMunicipalities.length === 0) {
        return <Navigate to={routes.municipalityAccessError()} />;
    }

    // if municipality is selected, check if its valid
    if (params.municipalityId) {
        if (
            !allowedMunicipalities.find(
                (am) => am.code === params.municipalityId
            )
        ) {
            const searchParams = new URLSearchParams();
            if (params.municipalityId) {
                searchParams.set("municipalityId", params.municipalityId);
            }

            return (
                <Navigate
                    to={`${routes.municipalityAccessDenied()}${
                        searchParams.toString()
                            ? `?${searchParams.toString()}`
                            : ""
                    } `}
                    replace
                />
            );
        }
    } else {
        // if no municipality is selected, redirect if only single municipality or show dropdown if more than one
        if (allowedMunicipalities.length > 1) {
            return <Navigate to={routes.municipalitySelect()} />;
        } else {
            return (
                <Navigate
                    to={routes.municipalityById(allowedMunicipalities[0].code)}
                    replace
                />
            );
        }
    }

    return <>{children}</>;
};

export default MunicipalityAuthorization;
