import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { appConfig } from "../../appConfig";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import TabBar, { TabBarView } from "../../components/TabBar";
import { UserContext } from "../../contexts/UserContext";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../../queries/queryKeys";
import { useCustomerDetailsQuery } from "../../queries/useCustomerDetailsQuery";
import CustomerOrdersView from "./CustomerOrdersView";
import CustomerSettingsView from "./CustomerSettingsView";
import {
    mapCustomerFromDTO,
    validateHandlaProxyButton,
} from "./customerDetailsConfig";

const CustomerDetailsView = () => {
    const HANDLA_REDIRECT_URL = appConfig.HANDLA_REDIRECT_URL;

    const { authUserData } = useContext(UserContext);
    const userId = authUserData?.uid;
    const municipalityCode = useSelectedMunicipalityId();

    const { customerId } = useParams<{ customerId: string }>();

    const customerDetailsQuery = useCustomerDetailsQuery({ customerId }, true);

    const customerOrders = useMemo(() => {
        return customerDetailsQuery.data?.orders;
    }, [customerDetailsQuery.data]);

    const customerReplacementWindows = useMemo(() => {
        return customerDetailsQuery.data?.replacements;
    }, [customerDetailsQuery.data]);

    const customerRawData = useMemo(() => {
        return customerDetailsQuery.data?.data;
    }, [customerDetailsQuery.data]);

    const lastSavedDetails = useMemo(() => {
        return (
            customerDetailsQuery.data &&
            mapCustomerFromDTO({
                customer: customerDetailsQuery.data.data,
                restrictions: customerDetailsQuery.data.restrictions,
                billingAddress: customerDetailsQuery.data.billingAddress,
            })
        );
    }, [customerDetailsQuery.data]);

    const queryClient = useQueryClient();

    const getCustomerDetails = () => {
        if (customerId) {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.byId(customerId, municipalityCode),
            });
        }
    };

    const tabBarConfig: TabBarView[] = [
        {
            title: "Beställningar",
            component: (
                <CustomerOrdersView
                    orders={customerOrders}
                    customerId={customerId}
                />
            ),
        },
        {
            title: "Inställningar",
            component: lastSavedDetails && (
                <CustomerSettingsView
                    lastSavedDetails={lastSavedDetails}
                    getCustomerDetails={getCustomerDetails}
                    replacements={customerReplacementWindows}
                    handlaProxyValidation={validateHandlaProxyButton(
                        lastSavedDetails,
                        customerRawData
                    )}
                />
            ),
            panelPadding: true,
        },
    ];

    const redirectToHandla = () => {
        window.location.href = `${HANDLA_REDIRECT_URL}/redirect/municipal/proxyLogin/${municipalityCode}/${customerRawData?.municipalityUnitCode}/${userId}/${customerRawData?.uid}`;
    };

    return (
        <Container>
            <SubHeader
                backArrow
                title={`${lastSavedDetails?.firstName || ""} ${
                    lastSavedDetails?.lastName || ""
                }`}
                rightContent={
                    <Button
                        size="small"
                        title="Handla"
                        disabled={
                            !validateHandlaProxyButton(
                                lastSavedDetails,
                                customerRawData
                            ).isValid
                        }
                        onClick={redirectToHandla}
                    />
                }
            />
            <Plate spaceBottom={true} padding={undefined}>
                {customerDetailsQuery.isLoading ? (
                    <Loader />
                ) : (
                    <TabBar
                        data={tabBarConfig}
                        initialViewIndex={customerOrders?.length ? 0 : 1}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default CustomerDetailsView;
