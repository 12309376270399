import { Logger } from "../utils/logger/Logger";

import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    AuthUser,
    customerMethods,
    unitMethods,
    userMethods,
} from "../api/apiMethods";
import { genericErrorConfig } from "../common/exceptions";
import { ReplacementWindowGroup, TimeRestriction } from "../common/types";
import { ModalContext } from "../components/Modal";
import { UserContext } from "../contexts/UserContext";

import {
    CustomerBillingDTO,
    CustomerDTO,
    CustomerOrderDTO,
} from "../api/apiTypes";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

export interface CustomerDetailsItem {
    data: CustomerDTO;
    billingAddress?: CustomerBillingDTO;
    orders: CustomerOrderDTO[];
    restrictions: TimeRestriction[];
    replacements: ReplacementWindowGroup[] | undefined;
}

async function getCustomerData(
    municipalityId: string,
    customerId: string,
    unitId: string,
    withBilling?: boolean
) {
    const allUnits = (await unitMethods(municipalityId).getAllUnits())
        .municipalityUnit;

    const customer = await customerMethods(municipalityId).getCustomer(
        unitId,
        customerId
    );

    if (customer.deactivationDate?.length) {
        return undefined;
    }

    const item: CustomerDTO = {
        ...customer,
        municipalityUnitName:
            allUnits.find((unit) => unit.code === customer.municipalityUnitCode)
                ?.name || "",
    };

    const getOrders = customerMethods(municipalityId)
        .getCustomerOrders(customer.uid)
        .then((response) => response.orders)
        .catch((e) =>
            Logger.errorWithMessage(`Failed to get orders for customer`, e)
        );

    const getRestrictions = customerMethods(municipalityId)
        .getCustomerRestriction(customer.municipalityUnitCode, customer.uid)
        .then((response) => response.homeTimeWindowRestrictions)
        .catch((e) =>
            Logger.errorWithMessage(
                `Failed to get restrictions for customer`,
                e
            )
        );

    const getBillingAddress = withBilling
        ? customerMethods(municipalityId)
              .getCustomerBilling(customer.municipalityUnitCode, customer.uid)
              .catch((e) =>
                  Logger.errorWithMessage(
                      `Failed to get billingAddress for customer`,
                      e
                  )
              )
        : Promise.resolve({} as CustomerBillingDTO);

    const getReplacements = customerMethods(municipalityId)
        .getCustomerReplacementWindows(
            customer.municipalityUnitCode,
            customer.uid
        )
        .then((response) => response.windowReplacementConfigurations)
        .catch((e) =>
            Logger.errorWithMessage(
                `Failed to get replacements for customer`,
                e
            )
        );

    const customerItem: CustomerDetailsItem = await Promise.all([
        getOrders,
        getRestrictions,
        getBillingAddress,
        getReplacements,
    ]).then((values) => ({
        data: item,
        orders: values[0] ? values[0] : [],
        restrictions: values[1] ? values[1] : [],
        billingAddress: values[2] ? values[2] : ({} as CustomerBillingDTO),
        replacements: values[3] ? values[3] : ([] as ReplacementWindowGroup[]),
    }));

    return customerItem;
}

const shouldGetBillingAddress = (authUserData: AuthUser) => {
    return (
        authUserData?.municipalityRole === "ADMIN" ||
        authUserData?.municipalityRole === "ORDERER"
    );
};

export const useCustomerDetailsQuery = (
    { customerId }: { customerId?: string },
    enabled: boolean,
    initialData?: CustomerDetailsItem
) => {
    const { authUserData } = useContext(UserContext);
    const { setModalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();
    const navigate = useNavigate();

    const query = useQuery({
        queryKey: queryKeys.customer.byId(customerId, municipalityId),
        queryFn: async () => {
            if (!customerId) {
                throw new Error("No customer id");
            }
            if (!authUserData) {
                throw new Error("No auth user data");
            }
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            const customerUserData = await userMethods().getUser(customerId);

            if (!customerUserData) {
                throw new Error("No customer user data");
            }

            if (!customerUserData.municipalityUnitCode) {
                throw new Error("Customer has no municipality unit code");
            }

            try {
                return await getCustomerData(
                    municipalityId,
                    customerId,
                    customerUserData.municipalityUnitCode,
                    shouldGetBillingAddress(authUserData)
                );
            } catch (err) {
                throw new Error("Could not get customer data");
            }
        },
        placeholderData: initialData,
        enabled: !!authUserData && !!municipalityId && !!customerId && enabled,
    });

    useEffect(() => {
        if (query.isError) {
            setModalOptions(
                genericErrorConfig(() => {
                    setModalOptions({ visible: false });
                    navigate(-1);
                }, "Kunde inte hämta uppgifter för denna slutkund.")
            );
        }
    }, [navigate, query.isError, setModalOptions]);

    return query;
};
