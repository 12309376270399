import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { ordererMethods } from "../api/apiMethods";
import { genericErrorConfig } from "../common/exceptions";
import { ModalContext } from "../components/Modal";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";

export const useDeleteOrdererMutation = (options: {
    onSuccess: () => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const { setModalOptions } = useContext(ModalContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (ordererId?: string) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!ordererId) {
                throw new Error("No orderer id");
            }

            try {
                return ordererMethods(municipalityId).deleteOrderer(ordererId);
            } catch (err) {
                throw err;
            }
        },
        onSuccess: options.onSuccess,
        onError: (err) => {
            Logger.error(err);
            setModalOptions(
                genericErrorConfig(() => {
                    setModalOptions({ visible: false });
                }, "Kunde inte ta bort beställare.")
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.orderer.all(municipalityId),
            });
        },
    });

    return mutation;
};
