import React from "react";
import { useNavigate } from "react-router-dom";
import { MunicipalityOrg } from "../../common/types";
import MunicipalitySelector from "../../components/MunicipalitySelector";
import { useAllowedMunicipalities } from "../../hooks/useAllowedMunicipalities";
import { routes } from "../../routes";
import MunicipalityBaseView from "./MunicipalityBaseView";

const SelectMunicipalityView = () => {
    const municipalities = useAllowedMunicipalities();

    const navigate = useNavigate();

    if (!municipalities) return null;

    return (
        <MunicipalityBaseView
            title="Välj organisation att administrera"
            subTitle="Du kan byta organisation senare i ett senare steg.">
            <MunicipalitySelector
                municipalities={municipalities}
                onSelect={(municipality: MunicipalityOrg) =>
                    navigate(routes.customers(municipality.code))
                }
            />
        </MunicipalityBaseView>
    );
};

export default SelectMunicipalityView;
