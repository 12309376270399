import { useContext } from "react";
import { MunicipalityOrg } from "../common/types";
import { UserContext } from "../contexts/UserContext";
import { useAllowedMunicipalitiesQuery } from "../queries/useAllowedMunicipalitiesQuery";

export const useAllowedMunicipalities = () => {
    const { authUserData } = useContext(UserContext);
    const { data, isSuccess } = useAllowedMunicipalitiesQuery();

    let allowedMunicipalities: MunicipalityOrg[] | undefined = undefined;

    if (authUserData?.municipalityRole === "ORDERER") {
        if (authUserData?.municipalityCode) {
            allowedMunicipalities = [
                { code: authUserData.municipalityCode, name: "Municipality" },
            ];
        } else {
            allowedMunicipalities = [];
        }
    }

    if (authUserData?.municipalityRole === "ADMIN" && isSuccess) {
        allowedMunicipalities = data;
    }

    return allowedMunicipalities;
};
